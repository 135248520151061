import { Box, Button, Flex } from '@chakra-ui/react';
import Slider from 'react-slick';
import React, { useEffect, useRef, useState } from 'react';
import SliderButtons from './SliderButtons';
import useIsMobile from '../../hooks/useIsMobile';
import { NodeOrTeaserType } from '../../types/global-types';
import { forceCheck } from 'react-lazyload';
import { Maybe } from '../../generated/types';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';

interface Props {
  data: Array<NodeOrTeaserType> | Array<Maybe<NodeOrTeaserType>>;
  TitleComponent?: JSX.Element;
  CardComponent?: React.FC<{ data: NodeOrTeaserType }>;
  arrows?: boolean;
}

const TeaserSlider: React.FC<Props> = ({
  data,
  TitleComponent,
  arrows,
  CardComponent,
}) => {
  const timeoutRef = useRef<NodeJS.Timeout>();
  const isMobile = useIsMobile();
  const [currentSlide, setCurrentSlide] = useState(0);
  const slidesPerView = isMobile ? 1 : 3;
  const slickRef = useRef<Slider>(null);

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  if (!data) return null;

  const onNext = () => {
    slickRef.current && slickRef.current.slickNext();
  };
  const onPrev = () => {
    slickRef.current && slickRef.current.slickPrev();
  };

  const hasNext = currentSlide + slidesPerView < data.length;
  const hasPrevious = currentSlide > 0;
  const hasPages = Math.ceil(data.length / slidesPerView) > 1;

  const onInit = () => {
    timeoutRef.current = setTimeout(() => {
      forceCheck();
    }, 1);
  };

  return (
    <Box>
      {TitleComponent && (
        <Flex
          marginBottom={4}
          justifyContent="space-between"
          alignItems="center"
        >
          {TitleComponent}
          <Flex
            display={{
              base: 'none',
              md: 'flex',
            }}
            alignItems="center"
          >
            {hasPages && (
              <Box marginRight={4} fontSize="sm" color="gray.600">
                {Math.ceil(currentSlide / slidesPerView) + 1} /{' '}
                {Math.ceil(data.length / slidesPerView)}
              </Box>
            )}
            {data.length > slidesPerView && (
              <SliderButtons
                onNext={onNext}
                onPrev={onPrev}
                hasPrevious={hasPrevious}
                hasNext={hasNext}
              />
            )}
          </Flex>
        </Flex>
      )}
      <Box className="Slider" margin={-4}>
        <Slider
          onInit={onInit}
          beforeChange={(_, nextSlide) => setCurrentSlide(nextSlide)}
          afterChange={(currentSlide) => setCurrentSlide(currentSlide)}
          draggable={isMobile}
          ref={slickRef}
          centerMode={false}
          infinite={false}
          slidesToShow={slidesPerView}
          slidesToScroll={slidesPerView}
          arrows={typeof arrows !== 'undefined' && !isMobile ? arrows : false}
          prevArrow={
            typeof arrows !== 'undefined' ? (
              <Box
                left="-50px"
                width="auto"
                height="auto"
                __css={{
                  _before: {
                    display: 'none',
                  },
                }}
              >
                <Button fontSize="2rem" disabled={!hasPrevious} pl={0} pr={0}>
                  <ChevronLeftIcon />
                </Button>
              </Box>
            ) : null
          }
          nextArrow={
            typeof arrows !== 'undefined' ? (
              <Box
                right="-50px"
                width="auto"
                height="auto"
                __css={{
                  _before: {
                    display: 'none',
                  },
                }}
              >
                <Button fontSize="2rem" disabled={!hasNext} pl={0} pr={0}>
                  <ChevronRightIcon />
                </Button>
              </Box>
            ) : null
          }
          dots={isMobile}
          // responsive={[
          //   {
          //     breakpoint: 768,
          //     settings: {
          //       slidesToShow: 1,
          //       arrows: false,
          //     },
          //   },
          // ]}
        >
          {(data as NodeOrTeaserType[]).map((d) => (
            <Box key={d.id}>
              <Box
                margin={4}
                _focus={{
                  boxShadow: 'none',
                  outline: 'none',
                }}
              >
                {CardComponent && <CardComponent data={d} />}
              </Box>
            </Box>
          ))}
        </Slider>
      </Box>
    </Box>
  );
};

export default TeaserSlider;
