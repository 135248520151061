import { Box, IconButton } from '@chakra-ui/react';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';

interface Props {
    hasPrevious: boolean;
    hasNext: boolean;
    onPrev: () => void;
    onNext: () => void;
}

const SliderButtons: React.FC<Props> = ({
    hasPrevious,
    hasNext,
    onPrev,
    onNext,
}) => {
    return (
        <Box>
            <IconButton
                disabled={!hasPrevious}
                variant="outline"
                aria-label="Vorheriges"
                icon={<ChevronLeftIcon />}
                marginRight={1}
                onClick={onPrev}
            />
            <IconButton
                disabled={!hasNext}
                variant="outline"
                aria-label="Nächstes"
                icon={<ChevronRightIcon />}
                onClick={onNext}
            />
        </Box>
    );
};

export default SliderButtons;
