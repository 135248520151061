import { OfferItemFragmentFragment } from '../../../generated/types';
import ImageTextTeaserBase from '../../Common/ImageTextTeaserBase';
import getIconForCategoryKey from '../../../utils/getIconForCategoryKey';
import { Box, Flex, Heading } from '@chakra-ui/react';
import React from 'react';
import { HTMLText } from '../../Common/HTMLText';

interface OfferTeaserProps {
  data: OfferItemFragmentFragment;
  noCta: boolean;
}

const OfferTeaser: React.FC<OfferTeaserProps> = ({
  data,
  children,
  ...props
}) => {
  const Icon = getIconForCategoryKey('hotel');

  return (
    <ImageTextTeaserBase
      linkText="Check it now"
      url={data.hotel?.url}
      image={data.image?.teaser?.url || data.hotel?.teaserImage?.teaser.url}
      my={0}
      mx={0}
      bg={`rgba(0,0,0, .8)`}
      padding={{
        base: 3,
        lg: 8,
      }}
      {...props}
    >
      <Flex alignItems="center">
        <Box
          alignSelf="flex-start"
          padding={2}
          borderRadius={{
            base: 0,
            md: 'lg',
          }}
          display="inline-flex"
          fontSize={{
            base: 'xl',
            lg: '3xl',
          }}
          border="2px solid"
          borderColor="textColor"
          color="#fff"
        >
          <Icon />
        </Box>
        <Box
          ml={4}
          fontSize={{
            base: 'sm',
            lg: 'lg',
          }}
          fontWeight="bold"
        >
          <Box fontSize="sm">{data.hotel.region?.name}</Box>
          <Box>{data.hotel.title}</Box>
        </Box>
      </Flex>
      <Box mt={4}>
        <Heading
          fontSize={{
            base: 'lg',
            lg: 'xl',
          }}
        >
          {data.title}
        </Heading>
        {data.subtitle && (
          <Heading
            mt={1}
            fontWeight="medium"
            fontSize={{
              base: 'md',
              lg: 'lg',
            }}
          >
            {data.subtitle}
          </Heading>
        )}
        {data.price && (
          <Heading
            mt={2}
            fontSize={{
              base: 'xl',
              lg: '2xl',
            }}
          >
            {data.price} €
          </Heading>
        )}
        <Box marginY={2} width="60px" height="2px" bg="brand.600" />
        <HTMLText mt={4} text={data.body} noOfLines={4} truncate={180} />
      </Box>
      {children}
    </ImageTextTeaserBase>
  );
};

export default OfferTeaser;
