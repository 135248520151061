import { Flex } from '@chakra-ui/react';
import { ParagraphHotelOffers } from '../../../generated/types';
import Container from '../../Layout/Container';
import Wrapper from '../Wrapper';
import OfferTeaser from '../../Node/Hotel/OfferTeaser';
import React from 'react';
import ParagraphTitle from '../ParagraphTitle';
import TeaserSlider from '../../Common/TeaserSlider';

interface HotelOfferProps {
  label?: string;
  subLabel?: string;
  data: ParagraphHotelOffers;
}

const HotelOffers: React.FC<HotelOfferProps> = ({ label, subLabel, data }) => {
  if (data && data.data!.total === 0) return null;

  return (
    <Wrapper className="ParagraphHotelOffers">
      <Container>
        {data.title ||
          label ||
          subLabel ||
          (data.subtitle && (
            <Flex
              alignItems="center"
              justifyContent="space-between"
              marginBottom={4}
            >
              <ParagraphTitle
                label={data.title || label}
                sub={data.subtitle || subLabel}
              />
            </Flex>
          ))}

        <TeaserSlider
          data={data.data.items}
          CardComponent={OfferTeaser}
          arrows
        />
      </Container>
    </Wrapper>
  );
};

export default HotelOffers;
